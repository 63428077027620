
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    FilterFormAlarm,
    Chart,
    ChartError
} from "@/interfaces";
import * as types from "@/store/types";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption } from "@/utils";
import * as XLSX from 'xlsx'

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        FilterForm: () =>
            import("@/pages/components/forms/FilterFormAlarm.vue"),
        TabItem: () => import("@/components/layout/TabItem.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Resume extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormAlarm = {
        company: [],
        client: [],
        installation: [],
        elements: [],
        dates: [],
        valid: true
    };
    activeInterval: any = 0;
    CSVdia = "";
    start = "";
    end = "";
    start1 = "";
    start2 = "";
    end1 = "";
    end2 = "";
    first = false;
    alarmasTipo = { data: [] };
    alarmasFechaElemento = { data: [] };
    alarmasFechaTipo = { data: [] };
    alarmasElementoTipo = { data: [] };
    alarmas: any = [];
    errors: ChartError[] = [];
    noAlarma = true;
    searchMeter = "";
    searchAlarm = "";
    searchBox = "";
    search = "";
    cantidad = 0;
    consulta = "2023-11-10 00:00:00";
    history: Chart = { data: [] };
    boxes: any = [];
    logs: any = [];
    alarms: any = [];
    alarms1: any = [];
    measurer: any = [];
    AChistory = false;
    columnsAlarms = [
        {
            text: "Fecha Inicio",
            sortable: true,
            value: "inicio"
        },
        {
            text: "Fecha Final",
            sortable: true,
            value: "final"
        },
        {
            text: "Cliente",
            sortable: true,
            value: "cliente"
        },
        {
            text: "Instalación",
            sortable: true,
            value: "instalacion"
        },
        {
            text: "Elemento",
            sortable: true,
            value: "elemento"
        },
        {
            text: "Tipo",
            sortable: true,
            value: "alarma"
        }
    ];

    headersBox = [
        {
            text: "Caja",
            value: "box"
        },
        {
            text: "Instalacion",
            value: "instala"
        },
        {
            text: "Cliente",
            value: "cliente"
        },
        {
            text: "Ultima conexion",
            value: "ultima"
        },
        { text: "", value: "actions", sortable: false }
    ];

    headers = [
        { text: "Fecha", value: "date" },
        {
            text: "Caja",
            value: "box"
        },
        {
            text: "Log",
            value: "log"
        },
        {
            text: "Módulo",
            value: "module"
        }
    ];

    headersAlarm = [
        { text: "F. Inicio", value: "Finicio" },
        {
            text: "F. final",
            value: "Ffinal"
        },
        {
            text: "Elemento",
            value: "elemento"
        },
        {
            text: "Instalacion",
            value: "instala"
        },
        {
            text: "Cliente",
            value: "cliente"
        },
        {
            text: "Tipo",
            value: "tipo"
        },
        {
            text: "Descripcion",
            value: "descripcion"
        }
    ];

    headersMeter = [
        {
            text: "Cliente",
            value: "client"
        },
        {
            text: "Instalación",
            value: "install"
        },
        {
            text: "Medidor",
            value: "meter"
        },
        {
            text: "Ultimo Dato",
            value: "fecha"
        }
    ];

    mounted() {
        this.Refresh();
        // Iniciar la recarga automática cada 5 minutos (300,000 milisegundos)
        this.activeInterval = setInterval(() => {
            this.Refresh();
        }, 300000);
    }

    destroyed() {
        clearInterval(this.activeInterval);
    }

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }
    /**
     * Methods
     */

    getColor(fecha) {
        const aux: any =
            (Number(new Date()) - Number(new Date(fecha))) / 3600000;
        if (aux <= 3) return "green";
        else if (aux > 3 && aux <= 24) return "orange";
        else return "red";
    }

    async fetchData() {
        this.setProgressBar(true);
        this.alarmasTipo = { data: [] };
        this.alarmasFechaElemento = { data: [] };
        this.alarmasFechaTipo = { data: [] };
        this.alarmasElementoTipo = { data: [] };
        this.alarmas = [];
        this.noAlarma = true;

        if (this.filterForm.dates) {
            const aux0 = new Date(this.filterForm.dates[0]);
            let aux1;
            if (this.filterForm.dates.length > 1) {
                aux1 = new Date(this.filterForm.dates[1]);
                if (aux1 > aux0) {
                    this.end = this.filterForm.dates[1];
                    this.start = this.filterForm.dates[0];
                } else {
                    this.end = this.filterForm.dates[0];
                    this.start = this.filterForm.dates[1];
                }
            } else {
                this.start = this.filterForm.dates[0];
                this.end = this.start;
            }
        }

        const JSOON = this.filterForm.elements;

        try {
            const alarmDRes = await this.$api.listAlarm<responseChart>(
                this.start,
                this.end,
                JSOON
            );
            if (alarmDRes.ok) {
                this.noAlarma = alarmDRes.data["noAlarm"];
                if (!this.noAlarma) {
                    this.alarmas = alarmDRes.data["CSV"];
                    this.alarmasTipo = alarmDRes.data["fig_tip"];
                    this.alarmasFechaElemento =
                        alarmDRes.data["fig_fe_ele"];
                    this.alarmasFechaTipo = alarmDRes.data["fig_fe_tip"];
                    this.alarmasElementoTipo =
                        alarmDRes.data["fig_ele_tip"];
                    this.CSVdia = JSON.stringify(alarmDRes.data["CSV"]);
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }

        this.setProgressBar(false);
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        const timezone = String(this.$t("timeZone"));

        if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
            return (
                new Intl.DateTimeFormat(timezone, options).format(
                    new Date(this.filterForm?.dates[0])
                ) +
                " - " +
                new Intl.DateTimeFormat(timezone, options).format(
                    new Date(this.filterForm.dates[1])
                )
            );

        return "";
    }

    async Refresh() {
        this.history = { data: [] };
        this.AChistory = false;
        try {
            const boxResponse = await this.$api.getOffBox<responseChart>();
            if (boxResponse.ok) {
                this.boxes = boxResponse.data["caidas"];
                this.consulta = boxResponse.data["fecha"];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        try {
            const meterResponse = await this.$api.getMeterStatus<
                responseChart
            >();
            if (meterResponse.ok) {
                this.measurer = meterResponse.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        try {
            const logResponse = await this.$api.getLogs<responseChart>();
            if (logResponse.ok) {
                this.logs = logResponse.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        try {
            const alarmResponse = await this.$api.getAlarmsIns<responseChart>();
            if (alarmResponse.ok) {
                this.alarms1 = alarmResponse.data["alarmas"];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async Consulta(box) {
        try {
            const boxResponse = await this.$api.getHistory<responseChart>(box);
            if (boxResponse.ok) {
                this.AChistory = true;
                this.history = {
                    ...Eoption,
                    ...boxResponse.data["fig"]
                };
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    resetCSV() {
        this.CSVdia = "";
    }

    downloadCSVdia() {
        // const anchor = document.createElement("a");
        // anchor.href =
        //     "data:text/csv;charset=utf-16," + encodeURIComponent(this.CSVdia);
        // anchor.target = "_blank";
        // anchor.download =
        //     "Alarmas_" + this.start + "_" + this.end + ".csv";
        // anchor.click();
        const ws = XLSX.utils.json_to_sheet(JSON.parse(this.CSVdia));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "ALARMA");
        XLSX.writeFile(wb,"Alarmas_" + this.start + "_" + this.end + ".xlsx");
    }
}
