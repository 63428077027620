/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */

import * as echarts from "echarts";

export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function(this, ...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

export const sleep = async (delay: number) => {
    return new Promise(resolve => setTimeout(resolve, delay));
};

export const Eoption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
            label: {
                show: true
            }
        }
    },
    toolbox: {
        show: true,
        feature: {
            restore: { show: false },
            saveAsImage: {
                show: true,
                type: ["png", "jpg", "jpeg"],
                excludeComponents: ["toolbox", "dataZoom"]
            },
            dataZoom: { show: true }
        }
    },
    calculable: true,
    legend: {
        data: [],
        itemGap: 5
    },
    grid: {
        top: "10%",
        left: "1%",
        right: "1%",
        containLabel: true
    },
    dataset: {
        source: [],
        dimensions: []
    },
    xAxis: {
        type: "time",
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        }
    },
    yAxis: [
        {
            type: "value",
            axisLabel: {
                formatter: function(a) {
                    return isFinite(a) ? echarts.format.addCommas(a) : "";
                }
            },
            splitArea: {
                show: false
            }
        }
    ],
    dataZoom: [
        {
            show: true,
            height: 30
        },
        {
            type: "inside",
            zoomOnMouseWheel: "shift",
            moveOnMouseWheel: false,
            preventDefaultMouseWheel: false
        }
    ]
};

export const noData = {
    title: {
        text: "No Data Available",
        padding: [200, 400],
        textAlign: "center",
        textVerticalAlign: "center",
        lineHeight: 56,
        textStyle: {
            fontSize: 30
        }
    }
};
